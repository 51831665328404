import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import detectEthereumProvider from '@metamask/detect-provider';

const ebtcContractAddress = '0x10d46D6F8f691d3439A781FC5E7BE598Ab67b393';
const ebchContractAddress = '0xe99e627f29914c31f36E7ef4cbB2f4556714D035'; 
const pulseChainRpcUrl = 'https://rpc.pulsechain.com';
const developerAddress = '0xe730202385d0ec81a306d622f63ba8b1145d17a0';
const contractABI = [
  {
    "inputs": [],
    "name": "currentHalving",
    "outputs": [
        {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "mintCount",
    "outputs": [
        {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": true,
    "inputs": [
      {
        "name": "_owner",
        "type": "address"
      }
    ],
    "name": "balanceOf",
    "outputs": [
      {
        "name": "balance",
        "type": "uint256"
      }
    ],
    "payable": false,
    "stateMutability": "view",
    "type": "function"
  },
  {
    "constant": false,
    "inputs": [],
    "name": "mint",
    "outputs": [],
    "payable": true,
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "currentHalving",
    "outputs": [
        {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }
    ],
    "stateMutability": "view",
    "type": "function"
  }
];

function App() {
  const [provider, setProvider] = useState(null);
  const [account, setAccount] = useState('');
  const [ebtcBalance, setEbtcBalance] = useState('Not Connected');
  const [isConnected, setIsConnected] = useState(false);
  const [donateChecked, setDonateChecked] = useState(true);
  const [donationAmount, setDonationAmount] = useState('5000');
  const [statusMessage, setStatusMessage] = useState('');
  const [messageColor, setMessageColor] = useState('black');
  const [currentHalving, setCurrentHalving] = useState(0);
  const [mintCount, setMintCount] = useState(0);
  const [currentCrypto, setCurrentCrypto] = useState('E.BTC'); // Default to E.BTC
  const ebchContractABI = contractABI;
  const [ebchCurrentHalving, setEbchCurrentHalving] = useState(0);
  const [ebchMintCount, setEbchMintCount] = useState(0);
  const [currentBlockNumber, setCurrentBlockNumber] = useState(null);
  const [lastBlockTime, setLastBlockTime] = useState(null);
  const [estimatedNextBlock, setEstimatedNextBlock] = useState(11); // Starting with an arbitrary 15 seconds for the demo
  const [currentBlockMintCount, setCurrentBlockMintCount] = useState(0);
  const baseUrl = "https://api.scan.pulsechain.com/api";
  const [timeSinceLastBlock, setTimeSinceLastBlock] = useState(0);
  const averageBlockTime = 12; // average time between blocks in seconds
  const [countdown, setCountdown] = useState(12); // Starting from 12 seconds for each block
  const [ebchBalance, setEbchBalance] = useState('Not Connected');


  
  const colors = {
    'E.BTC': '#f7931a', // Bitcoin Orange
    'E.BCH': '#0AC18E', // Bitcoin Cash Green
  };


  
  const style = {
    app: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      backgroundColor: '#1a1a1a', // Dark background for contrast
      color: '#f7931a',
      flexDirection: 'column',
      fontFamily: '"Roboto", sans-serif', // Set the font
    },
    button: {
      backgroundColor: '#f7931a',
      color: 'black',
      padding: '10px 20px',
      border: '1px solid #f7931a', // Subtle border
      borderRadius: '5px',
      cursor: 'pointer',
      marginBottom: '20px',
      fontWeight: 'bold', // Make text bold
    },
    input: {
      padding: '10px',
      marginBottom: '10px',
      border: 'none',
      borderRadius: '5px',
      width: '200px', // Set a fixed width for inputs
    },
    balance: {
      color: 'green', // Make balance number green
      fontWeight: 'bold', // Emphasize the balance
    },
    statusMessage: {
      padding: '10px',
      borderRadius: '5px',
      backgroundColor: '#000', // Black background
      color: '#0f0', // Green text
      fontFamily: '"Courier New", Courier, monospace', // Courier font
      whiteSpace: 'pre-wrap', // Preserve whitespaces and line breaks
      marginBottom: '10px',
      position: 'relative', // Required for pseudo-element positioning
    },
  
    blinkingCursor: {
      display: 'inline-block',
      marginLeft: '5px',
      backgroundColor: '#0f0', // Green background to simulate the cursor
      width: '10px', // Width of the cursor
      height: '1rem', // Height should match the line-height of the text
      animation: 'blink-animation 1s infinite', // Blinking animation
    },
    label: {
      marginLeft: '5px',
      cursor: 'pointer',
      fontSize: '16px', // Slightly larger font size for readability
    }
  };

  const pastHalvingStyle = {
    padding: '10px',
    backgroundColor: '#333', // Grey background for past halvings
    color: '#FFF', // White text color for better contrast on grey
  };

  const currentHalvingStyle = {
    padding: '10px',
    backgroundColor: '#f7931a', // Orange background for current halving
    color: 'black', // Black text color for better contrast on orange
    fontWeight: 'bold',
  };

  const futureHalvingStyle = {
    padding: '10px',
    backgroundColor: '#333', // Grey background for future halvings
    color: '#FFF', // White text color for better contrast on grey
  };


  


  const dynamicStyle = {
    ...style,
    app: {
      ...style.app,
      color: colors[currentCrypto], // Use dynamic color for text
    },
    button: {
      ...style.button,
      backgroundColor: colors[currentCrypto], // Use dynamic color for button background
      border: `1px solid ${colors[currentCrypto]}`, // Use dynamic color for button border
    },
    borderHighlight: {
      border: `2px solid ${colors[currentCrypto]}`, // Dynamic border color for elements like divs
    },
    // Any other dynamic style changes based on the crypto selection
  };

  useEffect(() => {
    const init = async () => {
      const detectedProvider = await detectEthereumProvider();
      if (!detectedProvider) {
        console.error('Please install MetaMask!');
        return;
      }
      const ethProvider = new ethers.providers.Web3Provider(detectedProvider);
      setProvider(ethProvider);
      ethProvider.on("chainChanged", (_chainId) => window.location.reload());
    };
    init();
  }, []);

  const fetchAndSetBalance = async () => {
    if (!provider || !account) return;
    const pulseChainProvider = new ethers.providers.JsonRpcProvider(pulseChainRpcUrl);
    let contractAddress = currentCrypto === 'E.BTC' ? ebtcContractAddress : ebchContractAddress;
    let contract = new ethers.Contract(contractAddress, contractABI, pulseChainProvider);
  
    try {
      const balance = await contract.balanceOf(account);
      const formattedBalance = ethers.utils.formatUnits(balance.toString(), 'ether');
  
      if (currentCrypto === 'E.BTC') {
        setEbtcBalance(formattedBalance);
      } else {
        setEbchBalance(formattedBalance);
      }
  
      const halving = await contract.currentHalving();
      const mintCount = await contract.mintCount();
      if (currentCrypto === 'E.BTC') {
        setCurrentHalving(halving.toNumber());
        setMintCount(mintCount.toNumber());
      } else {
        setEbchCurrentHalving(halving.toNumber());
        setEbchMintCount(mintCount.toNumber());
      }
    } catch (err) {
      console.error('An error occurred while fetching the balance:', err);
    }
  };
  
  useEffect(() => {
    // Decrement the countdown timer by 1 second if it's greater than 0
    if (countdown > 0) {
      const timer = setInterval(() => {
        setCountdown(prevCountdown => prevCountdown - 1);
      }, 1000);
  
      // Clear the interval when the component unmounts or the countdown reaches 0
      return () => clearInterval(timer);
    }
  }, [countdown]); // This effect only depends on the countdown state
  

  useEffect(() => {
    fetchAndSetBalance();
  }, [currentCrypto, account, provider]);
  
  
  useEffect(() => {
    let isSubscribed = true;
  
    const fetchCurrentBlockAndTimestamp = async () => {
      try {
          const blockNumberResponse = await fetch(`${baseUrl}?module=block&action=eth_block_number`);
          if (!blockNumberResponse.ok) {
              throw new Error('Failed to fetch the current block number.');
          }
          const blockNumberData = await blockNumberResponse.json();
          const newBlockNumber = parseInt(blockNumberData.result, 16);
  
          if (isSubscribed && newBlockNumber !== currentBlockNumber) {
              setCurrentBlockNumber(newBlockNumber);
              setCountdown(averageBlockTime); // Reset the countdown when a new block is detected
  
              const blockDetailsResponse = await fetch(`${baseUrl}?module=block&action=getblockreward&blockno=${newBlockNumber}`);
              if (!blockDetailsResponse.ok) {
                  throw new Error('Failed to fetch block details.');
              }
              const blockDetailsData = await blockDetailsResponse.json();
  
              if (blockDetailsData.status && blockDetailsData.status === '1') {
                  const timestamp = blockDetailsData.result.timeStamp;
                  setLastBlockTime(timestamp);
              }
          }
      } catch (error) {
          console.error("Error fetching block number and details:", error);
      }
  };
  
  
    const intervalId = setInterval(fetchCurrentBlockAndTimestamp, 1000); // Check every second
  
    return () => {
      clearInterval(intervalId);
      isSubscribed = false;
    };
  }, [currentBlockNumber, baseUrl]); // Depend on `currentBlockNumber` to reset the effect, ensuring it's up-to-date
  

  const switchToPulseChain = async () => {
    const detectedProvider = await detectEthereumProvider();
  
    if (!detectedProvider) {
      console.log('MetaMask is not detected.');
      return false;
    }
  
    try {
      await detectedProvider.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId: '0x171', 
          chainName: 'PulseChain',
          nativeCurrency: {
            name: 'Pulse',
            symbol: 'PLS',
            decimals: 18,
          },
          rpcUrls: [pulseChainRpcUrl],
          blockExplorerUrls: ['https://explorer.pulsechain.com'],
        }],
      });
      return true;
    } catch (addError) {
      console.error('Error adding PulseChain to MetaMask:', addError);
      return false;
    }
  };


  const connectMetaMask = async () => {
    if (!provider) {
      console.log('Provider is not set.');
      return;
    }

    try {
      const switched = await switchToPulseChain();
      if (!switched) {
        alert('Please switch to PulseChain manually in MetaMask to use this DApp.');
        return;
      }

      const onPulseChain = await isOnPulseChain();
      if (!onPulseChain) {
        alert('You are not connected to the PulseChain network. Please switch to PulseChain.');
        return;
      }

      const accounts = await provider.send("eth_requestAccounts", []);
      if (accounts.length === 0) {
        console.log('No account found.');
        return;
      }

      setAccount(accounts[0]);
      setIsConnected(true);

      const pulseChainProvider = new ethers.providers.JsonRpcProvider(pulseChainRpcUrl);

      const ebtcContract = new ethers.Contract(ebtcContractAddress, contractABI, pulseChainProvider);
      const balance = await ebtcContract.balanceOf(accounts[0]);
      setEbtcBalance(ethers.utils.formatUnits(balance.toString(), 'ether'));
    } catch (err) {
      console.error('An error occurred during MetaMask connection:', err);
    }
  };

  const isOnPulseChain = async () => {
    try {
      const { chainId } = await provider.getNetwork();
      return chainId === 369; // PulseChain's Chain ID is 369
    } catch (error) {
      console.error('Error getting network:', error);
      return false;
    }
  };


  

  const getLatestBlock = async () => {
    try {
      const response = await fetch("https://api.scan.pulsechain.com/api?module=block&action=eth_block_number");
      if (!response.ok) {
        throw new Error('Failed to fetch the latest block number');
      }
      const data = await response.json();
      const latestBlockNumber = parseInt(data.result, 16);
      return latestBlockNumber;
    } catch (error) {
      console.error("Error fetching the latest block number:", error);
      return null;
    }
  };
  
const handleMintAndDonate = async () => {
  if (!provider || !account) return;

  const signer = provider.getSigner();
  const contractAddress = currentCrypto === 'E.BTC' ? ebtcContractAddress : ebchContractAddress;

  const onPulseChain = await isOnPulseChain();
  if (!onPulseChain) {
    alert('You are not connected to the PulseChain network. Please switch to PulseChain.');
    return;
  }

  try {
    if (donateChecked && donationAmount > 0) {
      setStatusMessage('Sending Donation... Please wait.');
      setMessageColor('green');

      // Convert donationAmount to wei
      const donationAmountWei = ethers.utils.parseUnits(donationAmount.toString(), 'ether');
      const donationTxResponse = await signer.sendTransaction({
        to: developerAddress,
        value: donationAmountWei
      });

      await donationTxResponse.wait();

      setStatusMessage('Donation Successful');
    }

    setStatusMessage('Checking for Mint Transaction...');
    setMessageColor('green');

    const ebContract = new ethers.Contract(contractAddress, contractABI, signer);

    setStatusMessage('Sending Mint... Please wait.');
    const mintTxResponse = await ebContract.mint();
    await mintTxResponse.wait();

    setStatusMessage('Mint Successful');
    setMessageColor('green');

    const updatedBalance = await ebContract.balanceOf(account);
    setEbtcBalance(ethers.utils.formatUnits(updatedBalance.toString(), 'ether')); // Consider renaming to setCryptoBalance for clarity
  } catch (error) {
    setStatusMessage('An error occurred - Check PLS Balance, EBTC Balance, or TX Limit');
    setMessageColor('red');
    console.error('An error occurred:', error);
  }
};



  const handleConnectDisconnect = async () => {
    if (isConnected) {
      setIsConnected(false);
      setAccount('');
      setEbtcBalance('Not Connected');
    } else {
      await connectMetaMask();
    }
  };
  return (
    <div className="App" style={dynamicStyle.app}>
      {isConnected && (
      <div style={{ ...style.statusMessage, backgroundColor: '#000', color: '#0f0', marginBottom: '20px' }}>
        Connected Wallet: <span>{account}</span><br /><br />
        {currentCrypto} Balance: <span>
          {currentCrypto === 'E.BTC' ? ebtcBalance : ebchBalance}
        </span><br /><br />
        Current Block Number: {currentBlockNumber}<br /><br />
        Last Block Time: {lastBlockTime ? new Date(lastBlockTime * 1000).toLocaleString() : 'Fetching...'}<br /><br />
        Estimated Time To Next Block: {countdown} seconds<br /><br />
        <span>{statusMessage}</span>
      </div>
      )}
  
      <div style={{ color: 'white', margin: '10px' }}>
      </div>
      <div style={{ color: 'white', margin: '10px' }}>
      <div></div>
      </div>
      <div></div>

  
      <h1 style={{ fontSize: '32px', fontWeight: 'bold' }}>{`Minting Tool for ${currentCrypto}`}</h1>
      <div style={{ marginBottom: '20px' }}>
      <button
        onClick={() => setCurrentCrypto('E.BTC')}
        style={{...dynamicStyle.button, marginRight: '10px'}}
      >
        E.BTC
      </button>
      <button
        onClick={() => setCurrentCrypto('E.BCH')}
        style={dynamicStyle.button}
      >
        E.BCH
      </button>

      </div>
  
      <p style={{ color: '#FFF', marginTop: '10px', fontSize: '16px', maxWidth: '600px', textAlign: 'center' }}>
        A first-principles meme coin on Pulsechain. No official website. No founder allocation. Mint for free with this community-built tool.
      </p>
  
      <div style={{ maxWidth: '600px', width: '100%', background: '#222', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', margin: '20px auto', color: '#FFF', border: `2px solid ${colors[currentCrypto]}` }}>
        <header className="App-header" style={{ marginBottom: '20px', borderBottom: `1px solid ${colors[currentCrypto]}`, paddingBottom: '10px' }}>
          <h2 style={{ fontWeight: 'normal', fontSize: '20px', color: colors[currentCrypto] }}>What is {currentCrypto}?</h2>
          <ul style={{ paddingLeft: '20px', listStyleType: '"➤  "', color: '#FFF' }}>
            {currentCrypto === 'E.BTC' && (
              <>
                <li>Fair-Launch with 21 million total supply</li>
                <li>Halving of rewards every 210,000 transactions</li>
                <li>Limits minting to 5 transactions per PulseChain block</li>
                <li>No Pre-Mine and No founder Allocation</li>
              </>
            )}
            {currentCrypto === 'E.BCH' && (
              <>
                <li>Minters must hold 500 E.BTC to mint successfully</li>
                <li>Requires an additional 100 E.BTC held after each halving</li>
                <li>Limit of 5 reward mints per Pulsechain block - MUST BE FAST!</li>
                <li>Rewards are halved every 210,000 transactions</li>
              </>
            )}
          </ul>
        </header>

  
        <div style={{ marginTop: '20px' }}>
          {isConnected ? (
            <>
              <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                <label htmlFor="donate" style={{ marginBottom: '5px', color: colors[currentCrypto] }}>Optional Donation:</label>
                <input
                  type="number"
                  placeholder="Amount in PLS"
                  value={donationAmount}
                  onChange={(e) => setDonationAmount(e.target.value)}
                  style={{ ...style.input, backgroundColor: '#333', color: colors[currentCrypto], border: `1px solid ${colors[currentCrypto]}` }}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                <input
                  type="checkbox"
                  id="donate"
                  name="donate"
                  checked={donateChecked}
                  onChange={() => setDonateChecked(!donateChecked)}
                  style={{ marginRight: '10px' }}
                />
                <label htmlFor="donate" style={{ color: colors[currentCrypto] }}>Check to support developer or uncheck to cancel donation</label>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <button onClick={handleMintAndDonate} style={dynamicStyle.button}>Mint {currentCrypto}</button>
                <button onClick={handleConnectDisconnect} style={{ ...style.button, backgroundColor: 'black', color: colors[currentCrypto] }}>Disconnect</button>
              </div>
            </>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <button onClick={handleConnectDisconnect} style={dynamicStyle.button}>Connect MetaMask</button>
            </div>
          )}
        </div>
      </div>
  
      {isConnected && (
        <div style={{ maxWidth: '800px', margin: '20px auto', backgroundColor: '#222', padding: '20px', borderRadius: '8px', border: `2px solid ${colors[currentCrypto]}`, color: '#FFF' }}>
          <h2 style={{ color: colors[currentCrypto], textAlign: 'center' }}>Halving Schedule</h2>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ borderBottom: `1px solid ${colors[currentCrypto]}`, padding: '10px', color: colors[currentCrypto] }}>Halving</th>
                <th style={{ borderBottom: `1px solid ${colors[currentCrypto]}`, padding: '10px', color: colors[currentCrypto] }}>Reward</th>
                <th style={{ borderBottom: `1px solid ${colors[currentCrypto]}`, padding: '10px', color: colors[currentCrypto] }}>Transactions</th>
                <th style={{ borderBottom: `1px solid ${colors[currentCrypto]}`, padding: '10px', color: colors[currentCrypto] }}>Halving Count</th>
              </tr>
            </thead>
            <tbody>
            {[...Array(10).keys()].map((halvingIndex) => {
              // Calculate reward for each halving index
              const reward = (50 / Math.pow(2, halvingIndex)).toFixed(2); // Adjust the initial reward and formula as needed
              
              const isEBTC = currentCrypto === 'E.BTC';
              const currentHalvingLocal = isEBTC ? currentHalving : ebchCurrentHalving;
              const mintCountLocal = isEBTC ? mintCount : ebchMintCount;

              const transactions = halvingIndex < currentHalvingLocal ? (halvingIndex + 1) * 210000 : (halvingIndex === currentHalvingLocal ? mintCountLocal : 0);
              const halvingCount = (halvingIndex + 1) * 210000;
              const isCurrentHalving = halvingIndex === currentHalvingLocal;
              const backgroundColor = isCurrentHalving ? colors[currentCrypto] : '#333';
              const color = isCurrentHalving ? 'black' : '#666';
              const textDecoration = halvingIndex < currentHalvingLocal ? 'line-through' : 'none';

              return (
                <tr key={halvingIndex} style={{ backgroundColor, color, fontWeight: isCurrentHalving ? 'bold' : 'normal', textDecoration }}>
                  <td style={{ padding: '10px' }}>{halvingIndex}</td>
                  <td style={{ padding: '10px' }}>{reward}</td>
                  <td style={{ padding: '10px' }}>{transactions.toLocaleString()}</td>
                  <td style={{ padding: '10px' }}>{halvingCount.toLocaleString()}</td>
                </tr>
              );
            })}

            </tbody>
          </table>
        </div>
      )}
  
      <div style={{ marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', gap: '10px' }}>
        <div style={{ borderBottom: `1px solid ${colors[currentCrypto]}`, width: '30%' }}></div>
        <div style={{ borderBottom: `1px solid ${colors[currentCrypto]}`, width: '30%' }}></div>
      </div>
      <div style={{ marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', gap: '10px' }}>
        <a href="https://bafybeicb2hlad6zs4kc4yvn5xbbzti6krjtpoxrysg42d4e5s5oubbipum.ipfs.dweb.link/#/token/0x10d46D6F8f691d3439A781FC5E7BE598Ab67b393?tab=contract" target="_blank" rel="noopener noreferrer" style={{ color: '#666', textDecoration: 'underline' }}>
          Contract
        </a>
        <span style={{ color: colors[currentCrypto], fontSize: '20px' }}> | </span>
  
        <a href="https://github.com/Numberofthings/eBTC" target="_blank" rel="noopener noreferrer" style={{ color: '#666', textDecoration: 'underline' }}>
          White Paper
        </a>
        <span style={{ color: colors[currentCrypto], fontSize: '20px' }}> | </span>
        <a href="https://twitter.com/evmBitcoin" target="_blank" rel="noopener noreferrer" style={{ color: '#666', textDecoration: 'underline' }}>
          Twitter
        </a>
        <span style={{ color: colors[currentCrypto], fontSize: '20px' }}> | </span>
        <a href="https://t.me/+AluUSMn-TVY1OTc0" target="_blank" rel="noopener noreferrer" style={{ color: '#666', textDecoration: 'underline' }}>
          Telegram
        </a>
      </div>
      <p style={{ color: '#FFF', marginTop: '10px', fontSize: '12px', maxWidth: '600px', textAlign: 'center' }}>
        E.BTC CA: 0x10d46D6F8f691d3439A781FC5E7BE598Ab67b393</p>
      <p style={{ color: '#FFF', marginTop: '10px', fontSize: '12px', maxWidth: '600px', textAlign: 'center' }}>
        E.BCH CA: 0xe99e627f29914c31f36E7ef4cbB2f4556714D035</p>
  
      <p style={{ color: colors[currentCrypto], marginTop: '0px', fontSize: '12px', maxWidth: '600px', textAlign: 'center' }}>
        This tool was created by <a href="https://twitter.com/treecitywes" style={{ color: '#FFF', textDecoration: 'none' }} target="_blank">@TreeCityWes</a> and code is available on <a href="https://github.com/treecitywes" style={{ color: '#FFF', textDecoration: 'none' }} target="_blank">GitHub</a>.
      </p>
    </div>
  );
  
}
export default App;
